import { Link } from 'components'
import style from './Layout.module.css'

const Footer = () => {
  const isMobile = window.innerWidth < 576

  const renderLink = (label, url) => (
    <Link className={{ link: style['footer__section__link'] }} href={url}>
      {label}
    </Link>
  )

  const renderContent = () => {
    if (isMobile) {
      return (
        <div className={style['footer__content']}>
          {renderLink('關於我們', '/content/3')}
          {renderLink('購物說明', '/info/guide')}
          {renderLink('客服資訊', '/info/contact')}
        </div>
      )
    }

    return (
      <div className={style['footer__content']}>
        <div className={style['footer__section']}>
          <div className={style['footer__section__title']}>關於我們</div>
          {renderLink('品牌故事', '/content/3')}
          {renderLink('商店簡介', '/info/about')}
          {renderLink('隱私權及網站使用條款', '/info/privacy')}
        </div>
        <div className={style['footer__section']}>
          <div className={style['footer__section__title']}>購物說明</div>
          {renderLink('付款方式', '/info/guide')}
          {renderLink('運送方式', '/info/guide')}
          {renderLink('退換貨方式', '/info/guide')}
        </div>
        <div className={style['footer__section']}>
          <div className={style['footer__section__title']}>客服資訊</div>
          {renderLink('客服留言', '/info/coming-soon')}
          {renderLink('常見問題', '/info/coming-soon')}
          {renderLink('會員權益聲明', '/info/right')}
          {renderLink('聯絡我們', '/info/contact')}
        </div>
      </div>
    )
  }

  return (
    <div className={style.footer}>
      {renderContent()}
      <div className={style['footer__copyright']}>
        <div>
          本網站由慎康企業股份有限公司經營 統一編號：30955994
        </div>
        <div>本站最佳瀏覽環境請使用Google Chrome、Firefox或Edge以上版本</div>
      </div>
    </div>
  )
}

export default Footer
