import { Outlet, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import { useEffect } from 'react'
import { REDIRECT_SET } from 'constants/actionType'

const MemberLayout = () => {
  const location = useLocation()
  const { session, local } = useGlobalState()
  const navigate = useNavigate()
  const { accessToken } = local.state.token

  useEffect(() => {
    if (!accessToken) {
      const { pathname, search } = location
      const url = pathname + search
      session.dispatch({ type: REDIRECT_SET, url })
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  return <Outlet />
}

export default MemberLayout
