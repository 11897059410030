import cx from 'classnames'
import { useState, useRef, useEffect } from 'react'
import { Link } from 'components'
import style from './Popover.module.css'

const Popover = ({
  className = {},
  show = true,
  disabled = false,
  label,
  text,
  icon,
  leftIcon,
  rightIcon,
  align = 'left',
  children,
  onClick,
  ...props
}) => {
  const ref = useRef(null)

  const [open, setOpen] = useState(false)
  const handleClick = (event) => {
    if (!ref.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  if (!show) return null
  if (!leftIcon) leftIcon = icon

  return (
    <div
      ref={ref}
      className={cx(style.popover, className.popover)}
      onClick={() => setOpen(!open)}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      {...props}
    >
      <Link
        className={{ link: cx(style['popover__text'], className.link) }}
        label={label}
        text={text}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        onClick={() => setOpen(!open)}
      />
      <div
        className={cx(style['popover__content'], className.content)}
        style={{ display: open && children ? 'block' : 'none', [align]: 0 }}
      >
        {children}
      </div>
    </div>
  )
}

export default Popover
