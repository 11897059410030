import { useState, useEffect } from 'react'
import useGlobalState from 'hooks/useGlobalState'
import style from './LoadingBar.module.css'

const LoadingBar = () => {
  const { session } = useGlobalState()
  const { loading } = session.state
  const [visible, setVisible] = useState(false)
  const [t, setTimer] = useState(null)

  useEffect(() => {
    if (loading && !visible) {
      setTimer(
        setTimeout(() => {
          setVisible(true)
        }, 250),
      )
    }

    if (!loading && visible) {
      setVisible(false)
    }

    if (!loading) {
      clearTimeout(t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, visible])

  return (
    <div
      className={style.loader}
      style={{
        visibility: visible ? 'visible' : 'hidden',
        opacity: visible ? 1 : 0,
      }}
    >
      <div className={style['loader_bar']}>
        <div className={style['loader__progress']} />
      </div>
    </div>
  )
}

export default LoadingBar
