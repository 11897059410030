import { Outlet, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import { useEffect } from 'react'
import { REDIRECT_SET } from 'constants/actionType'
import { Breadcrumb, Link } from 'components'
import style from './MemberSidebar.module.css'

const MemberSidebar = () => {
  const location = useLocation()
  const { session, local } = useGlobalState()
  const navigate = useNavigate()
  const { accessToken } = local.state.token
  const { breadcrumb } = session.state
  const isMobile = window.innerWidth < 576

  useEffect(() => {
    if (!accessToken) {
      const { pathname, search } = location
      const url = pathname + search
      session.dispatch({ type: REDIRECT_SET, url })
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const renderBreadcrumb = () => (
    <Breadcrumb
      className={{
        breadcrumb: style['breadcrumb'],
        link: style['breadcrumb__link'],
      }}
      items={breadcrumb}
    />
  )

  const renderLink = ({ label, url }) => {
    const isActive = location.pathname === url
    return (
      <Link
        className={{ link: style['sidebar__link'] }}
        style={{ textDecoration: isActive ? 'underline' : 'none' }}
        label={label}
        href={url}
      />
    )
  }

  if (isMobile) {
    return (
      <div className={style.main}>
        <Outlet />
      </div>
    )
  }

  return (
    <div className={style.container}>
      {renderBreadcrumb()}
      <div className={style.content}>
        <div className={style.sidebar}>
          {renderLink({ label: 'sidebar.account', url: '/member/account' })}
          {renderLink({ label: 'sidebar.order', url: '/member/order' })}
          {renderLink({ label: 'password.title', url: '/member/password' })}
        </div>
        <div className={style.main}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default MemberSidebar
