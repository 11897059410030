import React, { useEffect, useState, cloneElement } from 'react'
import useMessage from 'hooks/useMessage'
import { Drawer } from 'components'

const TableDrawer = ({ open, data, action }) => {
  const { filters, filterValues } = data

  const message = useMessage()
  const [filterInputs, setFilterInputs] = useState(
    getFilterValMap(filterValues),
  )
  const configs = getFilterConfigs({
    filters,
    message,
    filterInputs,
    setFilterInputs,
  })

  useEffect(() => {
    setFilterInputs(getFilterValMap(filterValues))
  }, [filterValues])

  if (!filters || filters.length === 0) return null

  return (
    <Drawer
      title="table.filter.title"
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={handleSubmit(configs, filterInputs, action)}
    >
      {configs.map((filter) =>
        cloneElement(filter.render, { key: filter.id, label: filter.label }),
      )}
    </Drawer>
  )
}

function getFilterValMap(filterValues = []) {
  return filterValues.reduce((result, item) => {
    result[item.id] = item.value || ''
    return result
  }, {})
}

function handleSubmit(configs, filterInputs, action) {
  return () => {
    const filterVals = Object.entries(filterInputs).map(([key, value]) => {
      const filter = configs.find(({ id }) => id === key)
      return { id: filter.id, label: filter.label, value }
    })
    return action.handleSubmit(filterVals)
  }
}

function getFilterConfigs({ filters, message, filterInputs, setFilterInputs }) {
  if (!filters) return []

  return filters.map((filter) => ({
    ...filter,
    render: filter.render({
      message,
      // filterOp: filterOps[filter.id],
      // setFilterOp: (val) => setFilterOps({ ...filterOps, [filter.id]: val }),
      filterInput: filterInputs[filter.id],
      setFilterInput: (val) =>
        setFilterInputs({ ...filterInputs, [filter.id]: val }),
    }),
  }))
}

export default TableDrawer
