import { Outlet, useLocation } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import { Breadcrumb, Link } from 'components'
import style from './StaticSidebar.module.css'

const StaticSidebar = () => {
  const location = useLocation()
  const { session } = useGlobalState()
  const { breadcrumb } = session.state
  const isMobile = window.innerWidth < 576

  const renderBreadcrumb = () => (
    <Breadcrumb
      className={{
        breadcrumb: style['breadcrumb'],
        link: style['breadcrumb__link'],
      }}
      items={breadcrumb}
    />
  )

  const renderLink = ({ text, url }) => {
    const isActive = location.pathname === url
    return (
      <Link
        className={{ link: style['sidebar__link'] }}
        style={{ textDecoration: isActive ? 'underline' : 'none' }}
        text={text}
        href={url}
      />
    )
  }

  if (isMobile) {
    return (
      <div className={style.main}>
        <Outlet />
      </div>
    )
  }

  return (
    <div className={style.container}>
      {renderBreadcrumb()}
      <div className={style.content}>
        <div className={style.sidebar}>
          {renderLink({ text: '品牌故事', url: '/content/3' })}
          {renderLink({ text: '商店簡介', url: '/info/about' })}
          {renderLink({ text: '購物說明', url: '/info/guide' })}
          {renderLink({ text: '商店客服', url: '/info/contact' })}
          {renderLink({ text: '客服留言', url: '/info/coming-soon' })}
          {renderLink({ text: '常見問題', url: '/info/coming-soon' })}
          {renderLink({ text: '隱私權及網站使用條款', url: '/info/privacy' })}
          {renderLink({ text: '會員權益聲明', url: '/info/right' })}
        </div>
        <div className={style.main}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default StaticSidebar
