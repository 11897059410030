import { useContext } from 'react'
import MessageContext from 'contexts/MessageContext'

const useMessage = () => {
  const { messages } = useContext(MessageContext)
  return (message) => getMessage(messages, message)
}

function getMessage(messages, message) {
  if (!message) return ''

  if (typeof message === 'string') {
    return messages[message] || message
  }

  let { id, values, labels } = message
  if (typeof id === 'object') {
    values = id.values
    labels = id.labels
    id = id.id
  }
  const params = values || {}

  if (labels) {
    Object.entries(labels).forEach(([key, val]) => {
      params[key] = messages[val] || val
    })
  }

  let result = messages[id] || id
  Object.entries(params).forEach(([key, val]) => {
    result = result.replaceAll(`{${key}}`, val)
  })
  return result
}

export default useMessage
