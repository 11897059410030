import cx from 'classnames'
import { Link, Popover } from 'components'
import { VscChevronDown } from 'react-icons/vsc'
import style from './ContentLinkList.module.css'

const ContentLinkList = ({ items, layout = 'navbar', onClick }) => {
  const handleClick = (url) => (navigate) => {
    navigate(url)
    if (onClick) onClick()
  }

  const className = cx(style['navbar__menu__text'], {
    [style['navbar__menu__text--sidebar']]: layout === 'sidebar',
  })

  const renderLinks = () =>
    items.map((item) => {
      switch (item.type) {
        case 'POPULAR_PRODUCT':
          return renderPopularProduct({ text: item.label })
        case 'DROPDOWN':
          return renderDropdown({
            text: item.label,
            items: renderPageLinks({ items: item.value }),
          })
        case 'CATEGORY':
          return renderCategoryLink({
            text: item.label,
            value: item.value,
            className,
          })
        case 'TOPIC':
          return renderTopicLink({
            text: item.label,
            value: item.value,
            className,
          })
        case 'PAGE':
          return renderPageLink({
            text: item.label,
            value: item.value,
            className,
          })
        default:
          return null
      }
    })

  const renderPopularProduct = ({ text }) => {
    const className = cx(style['navbar__menu__text'], {
      [style['navbar__menu__text--sidebar']]: layout === 'sidebar',
    })

    return (
      <Link
        key={text}
        className={{ link: className }}
        onClick={handleClick('/popular')}
      >
        {text}
      </Link>
    )
  }

  const renderDropdown = ({ text, items }) => {
    if (!items || items.length === 0) return null

    if (layout === 'sidebar') {
      return (
        <div key={text}>
          <div className={style['navbar__group']}>{text}</div>
          <div className={style['navbar__group__content']}>{items}</div>
        </div>
      )
    }

    const className = cx(style['navbar__menu__text'], {
      [style['navbar__menu__text--sidebar']]: layout === 'sidebar',
    })

    return (
      <Popover
        key={text}
        className={{
          link: className,
          content: style['navbar__menu__content'],
        }}
        text={text}
        rightIcon={<VscChevronDown />}
      >
        {items}
      </Popover>
    )
  }

  const renderCategoryLink = ({ text, value, className }) => {
    const url = value.value ? `/category?id=${value.value}` : '/category'
    return (
      <Link
        key={text}
        className={{ link: className }}
        text={text}
        onClick={handleClick(url)}
      />
    )
  }

  const renderTopicLink = ({ text, value, className }) => (
    <Link
      key={text}
      className={{ link: className }}
      text={text}
      onClick={handleClick(`/topic/${value.value}`)}
    />
  )

  const renderPageLink = ({ text, value, className }) => (
    <Link
      key={text}
      className={{ link: className }}
      text={text}
      onClick={handleClick(`/content/${value.value}`)}
    />
  )

  const renderPageLinks = ({ items }) =>
    items.map((item) => {
      switch (item.type) {
        case 'CATEGORY':
          return renderCategoryLink({
            text: item.label,
            value: item.value,
            className: style['navbar__menu__content__link'],
          })
        case 'TOPIC':
          return renderTopicLink({
            text: item.label,
            value: item.value,
            className: style['navbar__menu__content__link'],
          })
        case 'PAGE':
          return renderPageLink({
            text: item.label,
            value: item.value,
            className: style['navbar__menu__content__link'],
          })
        default:
          return null
      }
    })

  return renderLinks()
}

export default ContentLinkList
