import { Outlet } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import { Breadcrumb, Message } from 'components'
import style from './CategorySidebar.module.css'
import ContentLinkList from 'containers/ContentLinkList/ContentLinkList'

const CategorySidebar = () => {
  const { session, local } = useGlobalState()
  const { sidebarLinks = [] } = local.state
  const { breadcrumb } = session.state
  const isMobile = window.innerWidth < 576

  const renderBreadcrumb = () => (
    <Breadcrumb
      className={{
        breadcrumb: style['breadcrumb'],
        link: style['breadcrumb__link'],
      }}
      items={breadcrumb}
    />
  )

  if (isMobile) {
    return (
      <div className={style.main}>
        <Outlet />
      </div>
    )
  }

  return (
    <div className={style.container}>
      {renderBreadcrumb()}
      <div className={style.content}>
        <div className={style.sidebar}>
          <div className={style['sidebar__section']}>
            <Message
              as="h1"
              className={style['sidebar__title']}
              id="sidebar.category"
            />
            <ContentLinkList layout="sidebar" items={sidebarLinks} />
          </div>
        </div>
        <div className={style.main}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default CategorySidebar
