import useGlobalState from 'hooks/useGlobalState'
import { Message, Modal } from 'components'
import style from './ConfirmModal.module.css'
import { CONFIRM_OPEN } from 'constants/actionType'

const ConfirmModal = () => {
  const { session, local } = useGlobalState()
  const { state, dispatch } = session
  const { title, label, open, onSubmit } = state.confirmModal

  const handleClose = () =>
    dispatch({
      type: CONFIRM_OPEN,
      item: { open: false, title: '', label: '', onSubmit: () => {} },
    })

  const handleSubmit = async () => {
    const ok = await onSubmit({ session, local })
    if (ok) handleClose()
  }

  return (
    <Modal
      className={{
        header: style['modal__header'],
        title: style['modal__title'],
        content: style['modal__content'],
      }}
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <Message id={label} />
    </Modal>
  )
}

export default ConfirmModal
