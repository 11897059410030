const { useEffect, useRef } = require('react')

const GoogleRecaptcha = (props) => {
  const ref = useRef(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?onload=onloadcallback&render=explicit`
    script.async = true
    script.id = 'google-recaptcha'
    document.querySelector('body')?.appendChild(script)

    window.onloadcallback = () => {
      window.grecaptcha.render(ref.current, {
        sitekey: '6Lfk8ssgAAAAAOCdkNASRqFuBuVllE6CCA-8QngL',
        theme: 'light',
      })
    }

    return () => {
      document.getElementById('google-recaptcha')?.remove()
    }
  }, [])

  return <div ref={ref} {...props} />
}

export default GoogleRecaptcha
