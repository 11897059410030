import * as type from 'constants/actionType'

export const initialState = {
  config: JSON.parse(localStorage.getItem('config') || '{}'),
  token: JSON.parse(localStorage.getItem('token')) || {
    accessToken: '',
    refreshToken: '',
  },
  user: JSON.parse(localStorage.getItem('user')) || {},
  products: JSON.parse(localStorage.getItem('products') || '[]'),
  productHash: localStorage.getItem('productHash') || '',
  categories: JSON.parse(localStorage.getItem('categories') || '[]'),
  categoryHash: localStorage.getItem('caetgoryHash') || '',
  pages: JSON.parse(localStorage.getItem('pages') || '[]'),
  pageHash: localStorage.getItem('pageHash') || '',
  navbarLinks: JSON.parse(localStorage.getItem('navbarLinks') || '[]'),
  sidebarLinks: JSON.parse(localStorage.getItem('sidebarLinks') || '[]'),
  cart: JSON.parse(localStorage.getItem('cart') || '[]'),
  checkout: JSON.parse(localStorage.getItem('checkout') || '{}'),
}

export function reducer(state, action) {
  switch (action.type) {
    case type.CONFIG_SET:
      localStorage.setItem('config', JSON.stringify(action.data) || [])
      return { ...state, config: action.data }
    case type.SIDEBAR_TOGGLE:
      localStorage.setItem('sidebarOpen', !state.sidebarOpen)
      return { ...state, sidebarOpen: !state.sidebarOpen }
    case type.TOKEN_SET:
      localStorage.setItem('token', JSON.stringify(action.token))
      return { ...state, token: action.token }
    case type.TOKEN_REMOVE:
      const token = { accessToken: '', refreshToken: '' }
      localStorage.setItem('token', JSON.stringify(token))
      return { ...state, token }
    case type.USER_SET:
      localStorage.setItem('user', JSON.stringify(action.user))
      return { ...state, user: action.user }
    case type.CATEGORY_SET:
      localStorage.setItem('categoryHash', action.hash || '')
      localStorage.setItem('categories', JSON.stringify(action.data) || [])
      return { ...state, categoryHash: action.hash, categories: action.data }
    case type.PAGE_SET:
      localStorage.setItem('pageHash', action.hash || '')
      localStorage.setItem('pages', JSON.stringify(action.data) || [])
      return { ...state, pageHash: action.hash, pages: action.data }
    case type.LINK_SET:
      return { ...state, ...getLinks(action.data) }
    case type.CART_SET:
      localStorage.setItem('cart', JSON.stringify(action.data) || [])
      return { ...state, cart: action.data }
    case type.CHECKOUT_SET:
      localStorage.setItem('checkout', JSON.stringify(action.data) || [])
      return { ...state, checkout: action.data }
    default:
      return state
  }
}

function getLinks(data) {
  const navbarLinks = []
  const sidebarLinks = []

  data.forEach((item) => {
    if (item.source === 'NAVBAR') navbarLinks.push(item)
    if (item.source === 'SIDEBAR') sidebarLinks.push(item)
  })

  localStorage.setItem('navbarLinks', JSON.stringify(navbarLinks))
  localStorage.setItem('sidebarLinks', JSON.stringify(sidebarLinks))

  return { navbarLinks, sidebarLinks }
}
