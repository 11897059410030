import cx from 'classnames'
import { Link, Message } from 'components'
import style from './Breadcrumb.module.css'

const Breadcrumb = ({ className = {}, items, ...props }) => {
  if (!items) return null

  return (
    <ul className={cx(style.breadcrumb, className.breadcrumb)} {...props}>
      {items.map((item, index) => (
        <li
          key={index}
          className={cx(style['breadcrumb__item'], className.item)}
        >
          {item.href ? (
            <Link
              className={{
                link: cx(style['breadcrumb__link'], className.link),
              }}
              href={item.href}
              label={item.label}
              text={item.text}
            />
          ) : (
            <div className={style['breadcrumb__text']}>
              {item.label ? <Message id={item.label} /> : item.text}
            </div>
          )}
        </li>
      ))}
    </ul>
  )
}

export default Breadcrumb
