import {
  CATEGORY_SET,
  CONFIG_SET,
  PAGE_SET,
  LINK_SET,
} from 'constants/actionType'
import { request } from 'utilities/request'

export const handlers = () => ({
  handleLoad: async ({ local }) => {
    const { pageHash, categoryHash } = local.state
    const setConfig = async () => {
      const data = await getConfig()
      local.dispatch({ type: CONFIG_SET, data })
    }
    const setLinks = async () => {
      const data = await getLink()
      local.dispatch({ type: LINK_SET, data })
    }
    const setPages = async () => {
      const pageResp = await getPage()
      if (!pageResp.hash || pageResp.hash !== pageHash) {
        local.dispatch({ type: PAGE_SET, ...pageResp })
      }
    }
    const setCategories = async () => {
      const categoryResp = await getCategories()
      if (!categoryResp.hash || categoryResp.hash !== categoryHash) {
        local.dispatch({ type: CATEGORY_SET, ...categoryResp })
      }
    }
    await Promise.all([setConfig(), setPages(), setLinks(), setCategories()])
  },
})

async function getConfig() {
  const [ok, data] = await request({
    url: process.env.REACT_APP_APP_URL,
    body: {
      action: 'get',
      data: { fields: ['configs'] },
    },
  })
  if (!ok) return {}
  return data.configs?.webapp
}

async function getPage() {
  const [ok, data] = await request({
    url: process.env.REACT_APP_CONTENT_URL,
    body: { action: 'findPage', data: {} },
  })
  if (!ok) return {}
  return data
}

async function getLink() {
  const [ok, data] = await request({
    url: process.env.REACT_APP_CONTENT_URL,
    body: { action: 'findLink', data: {} },
  })
  if (!ok) return []
  return data
}

async function getCategories() {
  const [ok, data] = await request({
    url: process.env.REACT_APP_CATEGORY_URL,
    body: { action: 'findShelf', data: {} },
  })
  if (!ok) return {}
  return data
}
