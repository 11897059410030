export function now() {
  return formatTimestamp(new Date())
}

export function formatTimestamp(date, delimiter = '-') {
  const hour = ('0' + date.getHours()).slice(-2)
  const minute = ('0' + date.getMinutes()).slice(-2)
  const second = ('0' + date.getSeconds()).slice(-2)
  return `${formatDate(date, delimiter)} ${hour}:${minute}:${second}`
}

export function formatDate(date, delimiter = '-') {
  if (!date) return null
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return `${year}${delimiter}${month}${delimiter}${day}`
}

export function formatTime(date) {
  const hour = ('0' + date.getHours()).slice(-2)
  const minute = ('0' + date.getMinutes()).slice(-2)
  return `${hour}:${minute}`
}

export function addDays(date, days) {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return formatDate(newDate)
}
