import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import useMessage from 'hooks/useMessage'
import ReactSelect from 'react-select'
import { Field } from 'components'

const accent0 = 'var(--color-accent-0)'
const accent1 = 'var(--color-accent-1)'
const light0 = 'var(--color-light-0)'
const light1 = 'var(--color-light-2)'
const light2 = 'var(--color-light-3)'
const dark = 'var(--color-dark-2)'

const styles = ({ showBorder, padding = '5.5px' }) => ({
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    // borderColor: isFocused ? accent1 : styles.borderColor,
    borderColor: showBorder
      ? isFocused
        ? accent1
        : styles.borderColor
      : light0,
    boxShadow: 'none',
    '&:hover': {
      // borderColor: isFocused ? accent1 : styles.borderColor,
      borderColor: showBorder
        ? isFocused
          ? accent1
          : styles.borderColor
        : light0,
    },
    fontSize: 'var(--fontsize-1)',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? accent1
      : isFocused
      ? light1
      : null,
    color: isDisabled ? light2 : isSelected ? light0 : dark,
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? accent1 : accent0),
      color: isDisabled ? light2 : light0,
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding,
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
    margin: '1px 0',
    lineHeight: 1,
  }),
})

const Select = (
  {
    className = {},
    padding,
    show = true,
    showBorder = true,
    id,
    label,
    placeholder,
    required,
    errMsg,
    options,
    value,
    fieldProps,
    ...props
  },
  ref,
) => {
  const message = useMessage()
  const inputRef = useRef()

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current && inputRef.current.focus(),
  }))

  const customFilter = ({ data }, searchText) => {
    return data.label.toLowerCase().includes(searchText.toLowerCase())
  }

  if (!show) return null

  return (
    <Field
      className={{ field: className.field }}
      id={id}
      label={label}
      required={required}
      errMsg={errMsg}
      {...fieldProps}
    >
      <ReactSelect
        ref={inputRef}
        placeholder={placeholder ? message({ id: placeholder }) : ''}
        options={options}
        filterOption={customFilter}
        styles={styles({ showBorder, padding })}
        value={!value || Object.keys(value).length === 0 ? null : value}
        {...props}
      />
    </Field>
  )
}

export default forwardRef(Select)
