import { Button } from 'components'
import style from './Sidebar.module.css'
import { MdClose } from 'react-icons/md'
import ContentLinkList from 'containers/ContentLinkList/ContentLinkList'

const url = process.env.REACT_APP_STATIC_URL

const Sidebar = ({ local, open, onClose }) => {
  const { config, navbarLinks = [] } = local.state
  const { brand, headerLogo, color } = config || {}

  const renderLogo = () => {
    let logo = <h1>{brand}</h1>
    if (headerLogo) {
      logo = <img src={`${url}/1/crystale/app/${headerLogo}`} alt={brand} />
    }
    return (
      <div
        className={style['sidebar__logo']}
        style={{ backgroundColor: color }}
      >
        {logo}
        <Button icon={<MdClose />} onClick={onClose} />
      </div>
    )
  }

  return (
    <>
      <div
        className={style['sidebar__overlay']}
        style={{
          transform: open ? 'scale(1)' : 'scale(0)',
          opacity: open ? 0.6 : 0,
        }}
      />
      <div
        className={style['sidebar__wrap']}
        style={{ width: open ? '300px' : 0 }}
      >
        <div className={style['sidebar']}>
          {renderLogo()}
          <ContentLinkList
            layout="sidebar"
            items={navbarLinks}
            onClick={onClose}
          />
        </div>
      </div>
    </>
  )
}

export default Sidebar
