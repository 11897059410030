import { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout, ConfirmModal } from 'containers'
import { LoadingLogo, LoadingBar, Notification } from 'components'
import Login from 'views/Login/Login'
import SignUpSuccess from 'views/Login/SignUpSuccess'
import CategorySidebar from 'containers/CategorySidebar/CategorySidebar'
import MemberSidebar from 'containers/MemberSidebar/MemberSidebar'
import MemberLayout from 'containers/MemberLayout/MemberLayout'
import StaticSidebar from 'containers/StaticSidebar/StaticSidebar'

const HomePage = lazy(() => import('views/HomePage/HomePage'))
const CategoryList = lazy(() => import('views/CategoryList/CategoryList'))
const PopularList = lazy(() => import('views/PopularList/PopularList'))
const ProductPage = lazy(() => import('views/ProductPage/ProductPage'))
const CartProductPage = lazy(() => import('views/CartPage/CartProductPage'))
const CartShippingPage = lazy(() => import('views/CartPage/CartShippingPage'))
const CartBuyerPage = lazy(() => import('views/CartPage/CartBuyerPage'))
const ContentPage = lazy(() => import('views/ContentPage/ContentPage'))
const ContentList = lazy(() => import('views/ContentList/ContentList'))
const PassForgetPage = lazy(() => import('views/PassForgetPage/PassForgetPage'))
const PassResetPage = lazy(() => import('views/PassResetPage/PassResetPage'))
const OrderList = lazy(() => import('views/OrderList/OrderList'))
const OrderPage = lazy(() => import('views/OrderPage/OrderPage'))
const OrderCancelPage = lazy(() =>
  import('views/OrderCancelPage/OrderCancelPage'),
)
const ActivatePage = lazy(() => import('views/ActivatePage/ActivatePage'))
const AccountPage = lazy(() => import('views/AccountPage/AccountPage'))
const PassEditPage = lazy(() => import('views/PassEditPage/PassEditPage'))
const AboutPage = lazy(() => import('views/StaticPage/AboutPage'))
const PrivacyPage = lazy(() => import('views/StaticPage/PrivacyPage'))
const RightPage = lazy(() => import('views/StaticPage/RightPage'))
const GuideLinePage = lazy(() => import('views/StaticPage/GuideLinePage'))
const ContactPage = lazy(() => import('views/StaticPage/ContactPage'))
const ComingSoonPage = lazy(() => import('views/StaticPage/ComingSoonPage'))

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingLogo />}>
        <Routes>
          <Route path="/activate" element={<ActivatePage />} />
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup/success" element={<SignUpSuccess />} />
            <Route path="/password/forget" element={<PassForgetPage />} />
            <Route path="/password/reset" element={<PassResetPage />} />
            <Route index element={<HomePage />} />
            <Route path="/" element={<CategorySidebar />}>
              <Route path="/category" element={<CategoryList />} />
              <Route path="/category/:slug" element={<CategoryList />} />
              <Route path="/product" element={<ProductPage />} />
              <Route path="/product/:slug" element={<ProductPage />} />
              <Route path="/content/:id" element={<ContentPage />} />
              <Route path="/topic/:id" element={<ContentList />} />
              <Route path="/popular" element={<PopularList />} />
            </Route>
            <Route path="/cart" element={<MemberLayout />}>
              <Route path="/cart/product" element={<CartProductPage />} />
              <Route path="/cart/shipping" element={<CartShippingPage />} />
              <Route path="/cart/buyer" element={<CartBuyerPage />} />
            </Route>
            <Route path="/member" element={<MemberSidebar />}>
              <Route path="/member/account" element={<AccountPage />} />
              <Route path="/member/password" element={<PassEditPage />} />
              <Route path="/member/order" element={<OrderList />} />
              <Route extact path="/member/order/:id" element={<OrderPage />} />
              <Route
                path="/member/order/:id/cancel"
                element={<OrderCancelPage />}
              />
            </Route>
            <Route path="/info" element={<StaticSidebar />}>
              <Route path="/info/about" element={<AboutPage />} />
              <Route path="/info/privacy" element={<PrivacyPage />} />
              <Route path="/info/right" element={<RightPage />} />
              <Route path="/info/guide" element={<GuideLinePage />} />
              <Route path="/info/contact" element={<ContactPage />} />
              <Route path="/info/coming-soon" element={<ComingSoonPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
      <LoadingBar />
      <Notification />
      <ConfirmModal />
    </BrowserRouter>
  )
}

export default App
