import style from './LoadingLogo.module.css'

const LoadingLogo = ({ children, ...props }) => (
  <div className={style.loader} {...props}>
    <div className={style['loader__icon']}>
      <Dot top="6px" left="6px" delay="0s" />
      <Dot top="6px" left="26px" delay="-0.4s" />
      <Dot top="6px" left="45px" delay="-0.8s" />
      <Dot top="26px" left="6px" delay="-0.4s" />
      <Dot top="26px" left="26px" delay="-0.8s" />
      <Dot top="26px" left="45px" delay="-1.2s" />
      <Dot top="45px" left="6px" delay="-0.8s" />
      <Dot top="45px" left="26px" delay="-1.2s" />
      <Dot top="45px" left="45px" delay="-1.6s" />
    </div>
    <div className={style['loader__text']}>{children || 'LOADING...'}</div>
  </div>
)

const Dot = ({ top, left, delay }) => (
  <div
    className={style['loader__icon__dot']}
    style={{ top, left, animationDelay: delay }}
  />
)

export default LoadingLogo
