import cx from 'classnames'
import { Message } from 'components'
import style from './Field.module.css'

const Field = ({
  className = {},
  id,
  label,
  required = false,
  errMsg,
  children,
  ...props
}) => {
  if (errMsg && typeof errMsg === 'string') {
    errMsg = { id: errMsg }
  }

  if (errMsg && errMsg.constructor === Array) {
    errMsg = { id: errMsg[0], values: errMsg[1] }
  }

  const renderLabel = () => {
    if (!label) return null
    let cls = style['field__label']
    if (required) cls = cx(cls, style['field__required'])
    return <Message className={cls} as="label" htmlFor={id} id={label} />
  }

  const renderError = () => {
    if (!errMsg) return null
    return <Message className={style['field__err']} id={errMsg} />
  }

  return (
    <div className={cx(style.field, className.field)} {...props}>
      {renderLabel()}
      {children}
      {renderError()}
    </div>
  )
}

export default Field
