import cx from 'classnames'
import useMessage from 'hooks/useMessage'
import { Field } from 'components'
import style from './TextArea.module.css'

const TextArea = ({
  id,
  label,
  placeholder,
  required,
  errMsg,
  onChange,
  fieldProps,
  ...props
}) => {
  const message = useMessage()
  const hasError = !!errMsg

  return (
    <Field
      id={id}
      label={label}
      required={required}
      errMsg={errMsg}
      {...fieldProps}
    >
      <textarea
        className={cx(style['textarea'], hasError && style['textarea--error'])}
        placeholder={message(placeholder)}
        onChange={({ target }) => onChange(target.value)}
        onBlur={({ target }) => onChange(target.value?.trim())}
        {...props}
      />
    </Field>
  )
}

export default TextArea
