import * as type from 'constants/actionType'

export const initialState = {
  alerts: [],
  loading: false,
  confirmModal: initConfirmModal(),
  breadcrumb: [],
  categoryId: '',
  redirect: '',
}

export function reducer(state, action) {
  switch (action.type) {
    case type.ALERT_ADD:
      return { ...state, alerts: addAlert(state.alerts, action.item) }
    case type.ALERT_REMOVE:
      return { ...state, alerts: removeAlert(state.alerts, action.idx) }
    case type.LOADING_SHOW:
      return { ...state, loading: true }
    case type.LOADING_HIDE:
      return { ...state, loading: false }
    case type.CONFIRM_OPEN:
      return { ...state, confirmModal: action.item }
    case type.CONFIRM_CLOSE:
      return { ...state, confirmModal: initConfirmModal() }
    case type.CATEGORY_ID_SET:
      return { ...state, categoryId: action.id }
    case type.BREADCRUMB_SET:
      return { ...state, breadcrumb: action.item }
    case type.REDIRECT_SET:
      return { ...state, redirect: action.url }
    default:
      return state
  }
}

function initConfirmModal() {
  return { open: false, title: '', label: '', onSubmit: () => {} }
}

function addAlert(list, data) {
  const exist = list.some((item) => item.message === data.message)
  if (exist) return list
  return [...list, data]
}

function removeAlert(list, idx) {
  const result = [...list]
  result.splice(idx, 1)
  return result
}

// function setMap(map, key, value) {
//   const result = { ...map }
//   result[key] = value
//   return result
// }
