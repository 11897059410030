export const ALERT_ADD = 'ALERT_ADD'
export const ALERT_REMOVE = 'ALERT_REMOVE'
export const LOADING_SHOW = 'LOADING_SHOW'
export const LOADING_HIDE = 'LOADING_HIDE'
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const TOKEN_SET = 'TOKEN_SET'
export const TOKEN_REMOVE = 'TOKEN_REMOVE'
export const USER_SET = 'USER_SET'
export const CONFIRM_OPEN = 'CONFIRM_OPEN'
export const CONFIRM_CLOSE = 'CONFIRM_CLOSE'
export const FILTER_SET = 'FILTER_SET'
export const PAGINATION_SET = 'PAGINATION_SET'
export const CONFIG_SET = 'CONFIG_SET'
export const PAGE_SET = 'PAGE_SET'
export const LINK_SET = 'LINK_SET'
export const CATEGORY_SET = 'CATEGORY_SET'
export const CATEGORY_ID_SET = 'CATEGORY_ID_SET'
export const BREADCRUMB_SET = 'BREADCRUMB_SET'
export const CART_SET = 'CART_SET'
export const CHECKOUT_SET = 'CHECKOUT_SET'
export const REDIRECT_SET = 'REDIRECT_SET'
