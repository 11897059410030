import { useEffect, useRef } from 'react'

const GoogleLogin = ({ onSubmit }) => {
  const ref = useRef(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: onSubmit,
      })
      window.google.accounts.id.renderButton(ref.current, {
        theme: 'outline',
        size: 'large',
        width: '304',
      })
    }
    script.async = true
    script.id = 'google-login'
    document.querySelector('body')?.appendChild(script)

    return () => {
      window.google?.accounts.id.cancel()
      document.getElementById('google-login')?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div ref={ref}></div>
}

export default GoogleLogin
