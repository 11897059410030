import useGlobalState from 'hooks/useGlobalState'
import { ALERT_REMOVE } from 'constants/actionType'
import { Button, Message } from 'components'
import { MdClose } from 'react-icons/md'
import style from './Notification.module.css'

const Notification = () => {
  const { session } = useGlobalState()
  const { state, dispatch } = session

  const handleClick = (idx) => {
    dispatch({ type: ALERT_REMOVE, idx })
  }
  const hidden = state.alerts.length === 0

  const renderItem = ({ type, message }, idx) => {
    setTimeout(() => handleClick(idx), 5000)
    return (
      <div
        key={idx}
        className={style['notification__item']}
        style={{ backgroundColor: `var(--color-${type}-1)` }}
      >
        <Message className={style['notification_message']} id={message} />
        <Button
          className={{ button: style['notification__close'] }}
          icon={<MdClose size="16px" />}
          onClick={() => handleClick(idx)}
        />
      </div>
    )
  }

  return (
    <div
      className={style.notification}
      style={{ display: hidden ? 'none' : 'block' }}
    >
      {state.alerts.map(renderItem)}
    </div>
  )
}

export default Notification
