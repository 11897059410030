import { useEffect } from 'react'
import useMessage from 'hooks/useMessage'
import style from './Login.module.css'
import { Message } from 'components'

const SignUpSuccess = () => {
  const message = useMessage()

  useEffect(() => {
    document.title = message({ id: 'signup.success' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={style['wrapper']} style={{ width: '600px' }}>
      <h1 className={style['title']}>會員註冊</h1>
      <div className={style['container']} style={{ padding: 'var(--space-3)' }}>
        <Message id="signup.success" />
      </div>
    </div>
  )
}

export default SignUpSuccess
