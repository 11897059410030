import { useEffect, useState } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import { handlers } from './layoutAction'
import Navbar from './Navbar'
import Footer from './Footer'
import Sidebar from './Sidebar'
import MemberDrawer from './MemberDrawer'
import style from './Layout.module.css'

const Layout = () => {
  const { local, session } = useGlobalState()
  const navigate = useNavigate()
  const { handleLoad } = handlers({ local })
  const [open, setOpen] = useState(false)
  const [memberOpen, setMemberOpen] = useState(false)

  useEffect(() => {
    handleLoad({ local })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar
        local={local}
        session={session}
        navigate={navigate}
        onOpen={() => setOpen(true)}
        onMemberOpen={() => setMemberOpen(!memberOpen)}
      />
      <Sidebar
        local={local}
        session={session}
        open={open}
        onClose={() => setOpen(false)}
      />
      <MemberDrawer open={memberOpen} onClose={() => setMemberOpen(false)} />
      <main className={style.main}>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default Layout
