import useMessage from 'hooks/useMessage'

const Message = ({ className, id, as, ...props }) => {
  const message = useMessage()
  const Tag = as || 'span'
  if (!id) return null

  return (
    <Tag className={className} {...props}>
      {message({ id })}
    </Tag>
  )
}

export default Message
