import { Link } from 'components'
import { useLocation } from 'react-router-dom'
import style from './MemberDrawer.module.css'

const MemberDrawer = ({ open, onClose }) => {
  const location = useLocation()

  const handleClick = (href) => (navigate) => {
    navigate(href)
    onClose()
  }

  const renderLink = ({ label, url }) => {
    const isActive = location.pathname === url
    return (
      <Link
        className={{ link: style['sidebar__link'] }}
        style={{ textDecoration: isActive ? 'underline' : 'none' }}
        label={label}
        onClick={handleClick(url)}
      />
    )
  }

  return (
    <div
      className={style['sidebar__wrap']}
      style={{ width: open ? '200px' : 0 }}
    >
      <div className={style['sidebar']}>
        {renderLink({ label: 'sidebar.account', url: '/member/account' })}
        {renderLink({ label: 'sidebar.order', url: '/member/order' })}
        {renderLink({ label: 'password.title', url: '/member/password' })}
      </div>
    </div>
  )
}

export default MemberDrawer
