import React from 'react'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'actions/formAction'
import { request } from 'utilities/request'
import { TextInput } from 'components'
import {
  TOKEN_SET,
  USER_SET,
  TOKEN_REMOVE,
  BREADCRUMB_SET,
  REDIRECT_SET,
} from 'constants/actionType'

export const initialState = () =>
  initializeState({
    username: '',
    password: '',
    password2: '',
  })

const signupValidation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
  ],
  password2: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
    {
      type: 'fieldEqual',
      name: 'password',
      message: 'signup.error.passNotMatch',
    },
  ],
  // recaptcha: [{ type: 'required', message: 'error.required' }],
}

const loginValidation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, activeTab }) => {
  const validation = activeTab === 'login' ? loginValidation : signupValidation
  const handleChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    username: (
      <TextInput
        fieldProps={{ style: { width: '100%' } }}
        id="username"
        autoFocus
        placeholder="login.field.username"
        value={state.username}
        autoComplete="username"
        onChange={handleChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        fieldProps={{ style: { width: '100%' } }}
        id="password"
        type="password"
        placeholder="login.field.password"
        value={state.password}
        autoComplete="current-password"
        onChange={handleChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    password2: (
      <TextInput
        fieldProps={{ style: { width: '100%' } }}
        id="password2"
        type="password"
        placeholder="login.field.password2"
        value={state.password2}
        autoComplete="current-password"
        onChange={handleChange('password2')}
        errMsg={state.__error__.password2}
      />
    ),
  }
}

export const handlers = ({ session, local, state, setState, navigate }) => ({
  handleLoad: async () => {
    const path = [{ label: 'app.home', href: '/' }, { label: 'nav.member' }]
    session.dispatch({ type: BREADCRUMB_SET, item: path })
  },
  handleSignupSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation: signupValidation })) return

    const recaptcha = window.grecaptcha.getResponse()
    window.grecaptcha?.reset()
    const [ok] = await registerUser({ session, local, state, recaptcha })
    if (!ok) return

    // session.dispatch({
    //   type: ALERT_ADD,
    //   item: { type: 'success', message: 'signup.success' },
    // })
    // getRedirect(session, navigate)
    navigate('/signup/success')
  },
  handleLoginSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation: loginValidation })) return

    const [ok, data] = await createToken({ session, local, state })
    if (!ok) return

    const { accessToken, refreshToken, claim } = data
    local.dispatch({ type: USER_SET, user: claim })
    local.dispatch({ type: TOKEN_SET, token: { accessToken, refreshToken } })
    getRedirect(session, navigate)
  },
  handleGoogleLogin: async ({ credential }) => {
    const [ok, data] = await createGoogleToken({ session, local, credential })
    if (!ok) return

    const { accessToken, refreshToken, claim } = data
    local.dispatch({ type: USER_SET, user: claim })
    local.dispatch({ type: TOKEN_SET, token: { accessToken, refreshToken } })
    getRedirect(session, navigate)
  },
  handleFacebookLogin: async ({ credential }) => {
    const [ok, data] = await createFacebookToken({ session, local, credential })
    if (!ok) return

    const { accessToken, refreshToken, claim } = data
    local.dispatch({ type: USER_SET, user: claim })
    local.dispatch({ type: TOKEN_SET, token: { accessToken, refreshToken } })
    getRedirect(session, navigate)
  },
})

function getRedirect(session, navigate) {
  let url = session.state.redirect
  if (url) {
    session.dispatch({ type: REDIRECT_SET, url: '' })
  } else {
    url = '/'
  }
  navigate(url)
}

async function registerUser({ session, local, state, recaptcha }) {
  const { username, password } = state
  return request({
    session,
    local,
    url: process.env.REACT_APP_USER_URL,
    body: {
      action: 'register',
      data: {
        source: 'MR_SHOP',
        username,
        password,
        extra: {
          activationPath: `https://${window.location.hostname}/activate`,
          recaptcha,
        },
      },
    },
  })
}

async function createToken({ session, local, state }) {
  const { username, password } = state
  return request({
    session,
    local,
    url: process.env.REACT_APP_TOKEN_URL,
    body: {
      action: 'createUser',
      data: { username, password },
    },
  })
}

async function createGoogleToken({ session, local, credential }) {
  return request({
    session,
    local,
    url: process.env.REACT_APP_TOKEN_URL,
    body: {
      action: 'createGoogle',
      data: { credential },
    },
  })
}

async function createFacebookToken({ session, local, credential }) {
  return request({
    session,
    local,
    url: process.env.REACT_APP_TOKEN_URL,
    body: {
      action: 'createFacebook',
      data: { accessToken: credential },
    },
  })
}

export async function handleLogout({ local, session, navigate }) {
  const { refreshToken } = local.state.token
  const [ok] = await request({
    session,
    local,
    url: process.env.REACT_APP_TOKEN_URL,
    body: { action: 'del', data: { refreshToken } },
  })
  if (!ok) return

  local.dispatch({ type: TOKEN_REMOVE })
  navigate('/login')
}
