import { useState, useEffect } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import useGlobalState from 'hooks/useGlobalState'
import useMessage from 'hooks/useMessage'
import { initialState, handlers, fields } from './loginAction'
import style from './Login.module.css'
import { Breadcrumb, Button, Link, Message } from 'components'
import GoogleRecaptcha from 'components/GoogleRecaptcha/GoogleRecaptcha'
import GoogleLogin from 'components/GoogleLogin/GoogleLogin'
import FacebookLogin from 'components/FacebookLogin/FacebookLogin'

const Login = () => {
  const { session, local } = useGlobalState()
  const message = useMessage()
  const navigate = useNavigate()
  const [state, setState] = useState(initialState())
  const [activeTab, setActiveTab] = useState('login')
  const { breadcrumb } = session.state
  const form = fields({ state, setState, activeTab })
  const handler = handlers({ session, local, state, setState, navigate })

  useEffect(() => {
    document.title = message({ id: 'login.title' })
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderTabLinks = () => (
    <div className={style['tab']}>
      <button
        className={cx(style['tab__link'], {
          [style['tab__link--active']]: activeTab === 'signup',
        })}
        style={{ borderRight: '1px solid var(--color-light-3)' }}
        onClick={() => setActiveTab('signup')}
      >
        註冊會員
      </button>
      <button
        className={cx(style['tab__link'], {
          [style['tab__link--active']]: activeTab === 'login',
        })}
        onClick={() => setActiveTab('login')}
      >
        會員登入
      </button>
    </div>
  )

  const renderSignupTab = () => {
    const isActive = activeTab === 'signup'
    return (
      <form
        className={style.form}
        style={{ display: isActive ? 'flex' : 'none' }}
        onSubmit={handler.handleSignupSubmit}
      >
        {form.username}
        {form.password}
        {form.password2}
        <GoogleRecaptcha />
        <Button
          className={{ button: style['form__button'] }}
          label="login.btn.signup"
          type="submit"
        />
      </form>
    )
  }

  const renderLoginTab = () => {
    const isActive = activeTab === 'login'
    return (
      <form
        className={style.form}
        style={{ display: isActive ? 'flex' : 'none' }}
        onSubmit={handler.handleLoginSubmit}
      >
        {form.username}
        {form.password}
        <Button
          className={{ button: style['form__button'] }}
          label="login.btn.login"
          type="submit"
        />
        <div className={style['form__forget-password']}>
          <Link href="/password/forget" label="login.btn.forgetPassword" />
        </div>
        <div className={style['form__divider']}>
          <Message id="login.message.or" />
        </div>
        <GoogleLogin onSubmit={handler.handleGoogleLogin} />
        <FacebookLogin onSubmit={handler.handleFacebookLogin} />
      </form>
    )
  }

  return (
    <div className={style.wrapper}>
      <Breadcrumb
        className={{
          breadcrumb: style['breadcrumb'],
          link: style['breadcrumb__link'],
        }}
        items={breadcrumb}
      />
      <div className={style['container']}>
        {renderTabLinks()}
        {renderSignupTab()}
        {renderLoginTab()}
        <div className={style['agreement']}>
          擁有帳號即表示你同意
          <Link text="隱私權及網站使用條款" href="/info/privacy" />
        </div>
      </div>
      <div className={style['footer']}>
        <em>提醒您：</em>
        我們不會以電話或簡訊通知變更付款方式，也不會要您前往ATM進行任何操作。若有任何疑慮，請洽詢165反詐騙專線。
      </div>
    </div>
  )
}

export default Login
