import { useState } from 'react'
import { Button, Link, Message, Popover } from 'components'
import {
  MdAccountCircle,
  MdDelete,
  MdMenu,
  MdSearch,
  MdShoppingCart,
} from 'react-icons/md'
import useMessage from 'hooks/useMessage'
import style from './Navbar.module.css'
import { ALERT_ADD, CART_SET, TOKEN_REMOVE } from 'constants/actionType'
import { formatCurrency } from 'utilities/string'
import { getFilepath } from 'views/CategoryList/categoryListAction'
import { IoImageOutline } from 'react-icons/io5'
import ContentLinkList from 'containers/ContentLinkList/ContentLinkList'

const url = process.env.REACT_APP_STATIC_URL
const filepath = getFilepath()

const Navbar = ({ session, local, onOpen, onMemberOpen }) => {
  const { token, config, navbarLinks = [], cart = [] } = local.state
  const { brand, headerLogo, color } = config || {}
  const message = useMessage()
  const [search, setSearch] = useState('')
  const isMobile = window.innerWidth < 576

  const renderLogo = () => {
    let logo = <h1>{brand}</h1>
    if (headerLogo) {
      logo = <img src={`${url}/1/crystale/app/${headerLogo}`} alt={brand} />
    }
    return (
      <Link className={{ link: style['navbar__logo'] }} href="/">
        {logo}
      </Link>
    )
  }

  const renderMember = () => (
    <Popover
      className={{
        link: style['navbar__action__text'],
        content: style['navbar__action__content'],
      }}
      align="right"
      icon={<MdAccountCircle />}
    >
      <Link
        className={{ link: style['navbar__menu__content__link'] }}
        href="/member/order"
        label="nav.order"
      />
      <Link
        className={{ link: style['navbar__menu__content__link'] }}
        href="/member/account"
        label="nav.member"
      />
      <Link
        show={!token.accessToken}
        className={{ link: style['navbar__menu__content__link'] }}
        href="/login"
        label="nav.login"
      />
      <Link
        show={!!token.accessToken}
        className={{ link: style['navbar__menu__content__link'] }}
        label="nav.logout"
        onClick={() => {
          local.dispatch({ type: TOKEN_REMOVE })
          session.dispatch({
            type: ALERT_ADD,
            item: { type: 'success', message: 'logout.success' },
          })
        }}
      />
    </Popover>
  )

  const renderCart = () => (
    <Popover
      className={{
        link: style['navbar__action__text'],
        content: style['navbar__action__content'],
      }}
      align="right"
      leftIcon={<MdShoppingCart />}
      rightIcon={renderCartItemCount()}
    >
      {renderCartItems()}
    </Popover>
  )

  const renderCartItems = () => {
    if (cart.length === 0) return null
    return (
      <div className={style['navbar__cart__content']}>
        <Message id="nav.cart.title" />
        {cart.map((item) => renderCartItem(item))}
        <div className={style['navbar__cart__action']}>
          <Link
            className={{ link: style['navbar__cart__button'] }}
            label="btn.checkout"
            href="/cart/product"
          />
        </div>
      </div>
    )
  }

  const renderCartItem = (item) => {
    const { id, name, slug, primaryImage, extra } = item
    const { salesPrice, orderPrice } = extra || {}
    return (
      <div key={id} className={style['product']}>
        {renderCartImage(primaryImage, name, slug)}
        <Link
          className={{ link: style['product__info'] }}
          href={`/product/${slug}`}
        >
          <div className={style['product__name']}>{name}</div>
          <div className={style['product__price']}>
            {formatCurrency(salesPrice || orderPrice)}
          </div>
        </Link>
        <div>
          <Button
            className={{ button: style['product__remove'] }}
            icon={<MdDelete />}
            onClick={() => {
              const data = cart.filter((item) => item.id !== id)
              local.dispatch({ type: CART_SET, data })
            }}
          />
        </div>
      </div>
    )
  }

  const renderCartImage = (primaryImage, name, slug) => {
    const thumb = `${filepath}/${primaryImage}`
    if (primaryImage) return <img src={thumb} alt={name} />

    return (
      <Link
        className={{ link: style['product__image-empty'] }}
        href={`/product/${slug}`}
      >
        <IoImageOutline />
      </Link>
    )
  }

  const renderCartItemCount = () => {
    if (cart.length === 0) return null

    // const itemCount = cart.reduce((result, item) => {
    //   result += item?.quantity || 0
    //   return result
    // }, 0)

    return <div className={style['navbar__cart__count']}>{cart.length}</div>
  }

  const renderToolbar = () => {
    if (isMobile) {
      return (
        <div
          className={style['navbar__action']}
          style={{ marginRight: 'var(--space-3)' }}
        >
          <Button
            className={{ button: style['navbar__menu-button'] }}
            icon={<MdAccountCircle />}
            onClick={onMemberOpen}
          />
          <Button
            className={{ button: style['navbar__menu-button'] }}
            leftIcon={<MdShoppingCart />}
            rightIcon={renderCartItemCount()}
            onClick={({ navigate }) => {
              navigate('/cart/product')
            }}
          />
        </div>
      )
    }

    return (
      <div className={style['navbar__toolbar']}>
        <div className={style['navbar__action']}>
          {renderMember()}
          {renderCart()}
        </div>
        <div className={style['navbar__search']}>
          <input
            className={style['navbar__search__input']}
            type="text"
            value={search}
            placeholder={message({ id: 'nav.search' })}
            onChange={({ target }) => setSearch(target.value)}
          />
          <Button
            className={{
              button: style['navbar__search__button'],
              icon: style['navbar__search__icon'],
            }}
            leftIcon={<MdSearch />}
            onClick={({ navigate }) => {
              navigate('/category')
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={style['nav-wrapper']}
      style={{ backgroundColor: color, color: '#fff' }}
    >
      <div className={style.navbar}>
        <div className={style['navbar__container']}>
          <Button
            className={{ button: style['navbar__menu-button'] }}
            icon={<MdMenu />}
            onClick={onOpen}
          />
          {renderLogo()}
          <div className={style['navbar__menu']}>
            <ContentLinkList items={navbarLinks} />
          </div>
        </div>
        {renderToolbar()}
      </div>
    </div>
  )
}

export default Navbar
