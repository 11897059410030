import { request } from 'utilities/request'
import { BREADCRUMB_SET } from 'constants/actionType'

const url = process.env.REACT_APP_STATIC_URL

export const handlers = ({ id, local, session, setState }) => ({
  handleLoad: async () => {
    const data = await getData({ session, id })
    setState(data)

    const category = await getCategory({ local, id })
    const path = getPaths(category)
    session.dispatch({ type: BREADCRUMB_SET, item: path })
  },
})

export function getFilepath() {
  return `${url}/${process.env.REACT_APP_MCH_ID}/crystale/shelf`
}

async function getData({ id, session }) {
  const [ok, data] = await request({
    session,
    url: process.env.REACT_APP_SHELF_URL,
    body: {
      action: 'find',
      data: {
        fields: [
          'id',
          'productId',
          'name',
          'slug',
          'primaryImage',
          'extra',
          'isPublic',
        ],
        query: {
          categoryShelfId: id,
          isPublic: true,
        },
        sort: [{ key: 'name', sort: 'asc' }],
      },
    },
  })
  if (!ok) return []

  return data.data.filter(({ isPublic }) => isPublic === undefined || isPublic)
}

export function getCategory({ local, id }) {
  const { categories } = local.state
  if (!categories) return null

  return categories.find((item) => `${item.id}` === `${id}`)
}

function getPaths(category) {
  if (!category) {
    return [{ label: 'app.home', href: '/' }, { label: 'category.all' }]
  }

  const breadcrumb = [{ label: 'app.home', href: '/' }]
  const path = category.path || []
  path.forEach((item) => {
    breadcrumb.push({ label: item.name, href: `/category/${item.id}` })
  })

  breadcrumb.push({ label: category.name })
  return breadcrumb
}
