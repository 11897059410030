import { useEffect } from 'react'

const FacebookLogin = ({ onSubmit }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://connect.facebook.net/en_US/sdk.js'
    script.onload = () => {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v13.0',
        })
      }
      window.FB.AppEvents.logPageView()
    }
    script.async = true
    script.id = 'facebook-login'
    document.querySelector('body')?.appendChild(script)

    window.checkLoginState = () => {
      window.FB.getLoginStatus(({ status, authResponse }) => {
        if (status === 'connected') {
          onSubmit({ credential: authResponse.accessToken })
        }
      })
    }

    return () => {
      document.getElementById('facebook-login')?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className="fb-login-button"
      data-size="large"
      data-width="304px"
      data-button-type="login_with"
      data-layout="default"
      data-auto-logout-link="false"
      data-use-continue-as="true"
      data-scope="public_profile,email"
      data-onlogin="checkLoginState()"
    ></div>
  )
}

export default FacebookLogin
